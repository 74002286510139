<!--评论-->
<template>
    <div class="body">
        <div class="comment-wrapper">
            <div v-if="type == '0'" class="business-name">您正在对 <span>{{initInfo.gooods_name}}</span> 进行点评</div>
            <div v-else class="business-name">您正在对 <span>{{initInfo.business_name}}</span> 进行点评</div>
            <div class="">
                <!--<div class="comment-star-text">评分</div>-->
                <div class="comment-star-wrapper">
                    <div class="comment-star-text">服务</div>
                    <van-rate v-model="starSev"
                              :size="28"
                              color="#DDA350"
                              void-icon="star"
                              void-color="#eee"
                    />
                    <div v-if="starSev" class="comment-star-num">{{starSev}}分</div>
                </div>
                <div class="comment-star-wrapper">
                    <div class="comment-star-text">菜品</div>
                    <van-rate v-model="starFood"
                              :size="28"
                              color="#DDA350"
                              void-icon="star"
                              void-color="#eee"
                    />
                    <div v-if="starFood" class="comment-star-num">{{starFood}}分</div>
                </div>
                <div class="comment-star-wrapper">
                    <div class="comment-star-text">环境</div>
                    <van-rate v-model="starEnv"
                              :size="28"
                              color="#DDA350"
                              void-icon="star"
                              void-color="#eee"
                    />
                    <div v-if="starEnv" class="comment-star-num">{{starEnv}}分</div>
                </div>

            </div>
            <textarea maxlength="128" class="comment-text" rows="8" placeholder="请填写评价内容" v-model="comment"></textarea>
            <div class="comment-img-wrapper">
                <van-uploader v-model="fileList" multiple max-count="6" :max-size="500 * 1024" upload-text="图片"
                              @oversize="overSizeTip"
                              :before-read="asyncBeforeRead"/>
            </div>
        </div>
        <div class="bottom-button" @click="submitComment">发表点评</div>
        <div class="comment-box">
            <div class="comment-pop">
                <img src="https://seetop-1257860468.file.myqcloud.com/html/img/pop2022051201.png"
                     class="comment-pop-img">
                <div class="comment-pop-title">点评成功</div>
                <div class="comment-pop-content">点评审核中，审查成功将予以展示</div>
                <div class="comment-pop-button">回到首页</div>
            </div>
        </div>
    </div>
</template>

<script>
  import { globalConfig } from '../../utils/mixin'
  import * as imageConversion from 'image-conversion'

  export default {
    name: 'Comment',
    mixins: [globalConfig],
    data () {
      return {
        id: 0,
        type: '',
        initInfo: [],
          starSev: 0,
          starFood: 0,
          starEnv: 0,
        comment: '',
        fileList: [
          // { url: 'https://img01.yzcdn.cn/vant/leaf.jpg' },
          // // Uploader 根据文件后缀来判断是否为图片文件
          // // 如果图片 URL 中不包含类型信息，可以添加 isImage 标记来声明
          // { url: 'https://cloud-image', isImage: true }
        ]
      }
    },
    methods: {
      submitComment: function () {
        const that = this
        console.log(this.starEnv, this.comment, this.fileList)
        if (this.starEnv && this.comment) {
        } else {
          this.$toast('请填写完整评论、选择评星')
          return
        }
        this.axios.post(this.apiUrl + 'mall/top_rank/comment', {
          // id: 183441,
          id: this.id,
          stars_sev: this.starSev,
          stars_food: this.starFood,
          stars_env: this.starEnv,
          comment: this.comment,
          img: this.fileList
        }).then((response) => {
          console.log('123456789', response)
          const data = response.data
          if (data.status === 1) {
            this.$toast({
              message: '感谢您的评论',
              onClose: function () {
                that.$router.replace('/commentList')
              }
            })
          } else {
            this.$toast(data.msg)
          }
        })
        // if (this.starSev && this.starFood && this.starEnv && this.comment) {
        //   const imgArr = this.fileList.map(function (value, index, array) {
        //     return value.content
        //   })
        //   const that = this
        //   this.axios.post(this.apiUrl + 'mall/comment/submitComment', {
        //     imgArr: imgArr,
        //     comment: that.comment,
        //     id: that.id,
        //     stars_sev: that.starSev,
        //     stars_food: that.starFood,
        //     stars_env: that.starEnv
        //   }).then((response) => {
        //     const data = response.data
        //     if (data.status === 1) {
        //       that.$toast({
        //         message: '感谢您的评论',
        //         onClose: function () {
        //           that.$router.push('/commentList')
        //         }
        //       })
        //     } else {
        //       that.$toast(data.msg)
        //     }
        //   })
        // } else {
        //   this.$toast('请填写完整评论')
        // }
      },
      overSizeTip: function () {
        this.$toast('文件大小不能超过500kb')
      },
      init: function () {
        const that = this
        this.axios.post(this.apiUrl + 'mall/init/comment', {
          id: that.id
        }).then((response) => {
            console.log(response)
          const data = response.data
          that.initInfo = data.info
        })
      },
      asyncBeforeRead: function (file) {
        this.$toast.loading({
          message: '加载中...'
        })
        return new Promise((resolve) => {
          imageConversion.compressAccurately(file, {
            size: 300 // 图片大小压缩到1024kb
          }).then(res => {
            this.$toast.clear()
            resolve(res)
          })
        })
      }
    },
    created () {
      this.id = this.$route.params.id
      this.type = this.$route.params.type ? this.$route.params.type : '0'
      this.init()
    }
  }
</script>

<style scoped>
    * {
        box-sizing: border-box;
    }

    .body {
        position: absolute;
        min-height: 100%;
        min-width: 100%;
        background-color: #eee;
        padding: 5vw 0;
    }

    .comment-wrapper {
        background-color: #ffffff;
        padding: 0.6rem;
        width: 92vw;
        margin: 0 4vw;
        border-radius: 4vw;
    }

    .business-name {
        width: 90%;
        text-align: center;
        color: #666;
        font-weight: bold;
        font-size: 13px;
        letter-spacing: 0.1rem;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
        margin: 0 auto;
        padding: 2vw 0 1vw 0;
    }

    .business-name span {
        margin: 0 0.25vw;
        color: #333;
    }

    .comment-star-wrapper {
        display: flex;
        flex-flow: row;
        align-items: center;
        font-size: 0.9rem;
        color: #292929;
        padding: 0.2rem 1rem;
        margin-top: 0.7rem;
        box-sizing: border-box;
    }

    .comment-star-text {
        margin-right: 1rem;
        color: #666;
        font-weight: 600;
    }

    .comment-star-num {
        font-size: 16px;
        margin-left: 4vw;
        font-weight: 600;
        color: #DDA350;
    }

    .comment-text {
        display: block;
        width: 94%;
        margin: 1rem auto 0;
    }

    textarea {
        padding: 1rem 1.2rem;
        outline-style: none;
        color: #333;
        font-size: 16px;
        height: 68vw;
        border-style: none;
        background-color: #f3f3f3;
        border-radius: 2vw;
    }

    textarea::-webkit-input-placeholder {
        color: #bcbcbc;
        font-size: 1rem;
    }

    textarea:-moz-placeholder {
        color: #bcbcbc;
        font-size: 16px;
    }

    textarea::-moz-placeholder {
        color: #bcbcbc;
        font-size: 16px;
    }

    textarea:-ms-input-placeholder {
        color: #bcbcbc;
        font-size: 16px;
    }

    .comment-img-wrapper {
        width: 93%;
        margin: 1rem auto 0;
    }

    .bottom-button {
        width: 92vw;
        margin: 6vw 4vw 2vw 4vw;
        height: 11vw;
        line-height: 11vw;
        border-radius: 11vw;
        text-align: center;
        color: #fff;
        background: #DDA350;
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0.25vw;
    }

    .comment-box {
        display: none;
        position: fixed;
        width: 100vw;
        height: 100vh;
        background: rgba(0, 0, 0, 0.5);
        top: 0;
    }

    .comment-pop {
        width: 80vw;
        margin: 36vw 10vw;
        height: 60vw;
        border-radius: 4vw;
        background: #fff;
    }

    .comment-pop-img {
        width: 28vw;
        height: 28vw;
        border-radius: 28vw;
        position: absolute;
        margin: -8vw 26vw;
    }

    .comment-pop-title {
        width: 100%;
        text-align: center;
        font-size: 23px;
        color: #333;
        font-weight: 600;
        padding-top: 22.5vw;
    }

    .comment-pop-content {
        width: 100%;
        text-align: center;
        font-size: 13px;
        margin-top: 4px;
        color: #999;
    }

    .comment-pop-button {
        width: 116px;
        margin: 8vw auto 2vw;
        height: 38px;
        line-height: 38px;
        border-radius: 38px;
        text-align: center;
        color: #fff;
        background: #DDA350;
        font-size: 17px;
        font-weight: 600;
        letter-spacing: 0.25vw;
    }
</style>
